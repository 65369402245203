<template>
  <div class="app-container">
    <CrudTable ref="table" entity="HermesClient" :columns="columns" />
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [{ field: 'name' }, { field: 'accountNumber', label: 'hermes.accountNumber' }]
    };
  }
};
</script>

<style scoped></style>
